@import "../variables/breakpoints";

@mixin mobile-only {
    @media screen and (max-width: $breakpoint-tablet - 1px) {
        @content;
    }
}

@mixin tablet-up {
    @media screen and (min-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin tablet-only {
    @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
        @content;
    }
}

@mixin desktop-up {
    @media screen and (min-width: $breakpoint-desktop) {
        @content;
    }
}

@mixin not-desktop {
    @media screen and (max-width: $breakpoint-desktop) {
        @content;
    }
}
