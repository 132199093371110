    @import "styles/variables";
    @import "styles/mixins";

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    @include container;

    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: $spacing-8;
    padding-bottom: $spacing-9;
}
